import React, { useState } from "react";
import ReactDOM from "react-dom";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
  OverlayView,
} from "react-google-maps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const CustomMarker = ({ position, label }) => (
  <OverlayView
    position={position}
    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
  >
    <FontAwesomeIcon icon={faLocationDot} style={{ color: "red", fontSize: "30px" }} />
  </OverlayView>
);

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDDNTpbNCfEDuE8PRGUcejuhW5tkdY6Vyg&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `382px` }} />,
    mapElement: <div style={{ height: `100%`, width: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => {
  const [selectedPlace, setSelectedPlace] = useState(null);

  const handleMarkerClick = () => {
    setSelectedPlace({
      lat: 18.520788,
      lng: 73.93299,
      address: "Genivis Remedies (I) Pvt. Ltd., Marvel Feugo, Office No. 6070, Opp. Seasons Mall, Magarpatta Road, Hadapsar, Pune, 411028."
    });
  };

  return (
    <>
      <GoogleMap
        defaultCenter={{ lat: 18.520788, lng: 73.93299 }}
        defaultZoom={14}
        options={{ minZoom: 14 }}
      >
        <Marker
          icon={{
            url: 'https://www.iconsdb.com/icons/preview/red/map-marker-2-xxl.png',
          }}
          position={{ lat: 18.520788, lng: 73.93299 }}
          label={{
            text: "Genivis Remedies Pvt. Ltd",
            color: "#ff0000",
            fontSize: "14px",
            fontWeight: "bold",
          }}
          onClick={handleMarkerClick}
        />
        {selectedPlace && (
          <InfoWindow
            position={{ lat: selectedPlace.lat, lng: selectedPlace.lng }}
            onCloseClick={() => setSelectedPlace(null)}
          >
            <div>
              <h6>Genivis Remedies Pvt. Ltd.</h6>
              <p>{selectedPlace.address}</p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
      <GoogleMap
        defaultZoom={2}
        defaultCenter={{ lat: 18.520788, lng: 73.93299 }}
      >
        <CustomMarker
          position={{ lat: 18.520788, lng: 73.93299 }}
          label="My Label"
        />
      </GoogleMap>
    </>
  );
});

export default MyMapComponent;
