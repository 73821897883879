import React from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";

export default function FranchiseModelDemo() {

    return (
        <div>
            <p className='subhead text-start'>Franchise Model Demo</p>

            <div className='mb-5'>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/3PvSpPxaVqM"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>

                </Row>

            </div>
        </div>
    )
}
