import React from 'react'
import {
    Nav,
    Button,
    Offcanvas, Navbar, NavDropdown, Form, Container,
    Row, Col, Carousel, Card,
    CardTitle, Tab
} from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";

import Layout from './Layout';
export default function Blogs() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/blogdetails');
    };
    const gettingfamilierDir = () => {
        navigate('/gettingfamilier');
    };
    const boosterDir = () => {
        navigate('/booster');
    };
    return (
        <div>

            <Layout>
                <div className='blogs-style pt-4'>
                    <Container fluid>
                        <div>
                            <Row className='justify-content-center'>
                                <Col lg="9">
                                    <Row>
                                        <Col lg="3">
                                            <p className='head'>
                                                Blogs
                                            </p>
                                        </Col>
                                        <Col lg="9">
                                            <p className='subhead'>
                                                Discover the intricate realm of generic medicines, delving into an ever-expanding industry that evolves with each passing day, offering a deeper insight into its multifaceted landscape and burgeoning opportunities.
                                            </p>
                                        </Col>
                                    </Row>

                                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                                        <Row >
                                            <Col sm={12}>
                                                <Nav variant="pills" className="flex-row">
                                                    <Nav.Item className='mb-2'>
                                                        <Nav.Link eventKey="first">All</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='mb-2'>
                                                        <Nav.Link eventKey="second">Health Care</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='mb-2'>
                                                        <Nav.Link eventKey="third">Tips</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='mb-2'>
                                                        <Nav.Link eventKey="fourth">Personal Care</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='mb-2'>
                                                        <Nav.Link eventKey="fifth">Generic Pharmacy Business</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item className='mb-2'>
                                                        <Nav.Link eventKey="sixth">Food</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                            </Col>
                                            <Col sm={12}>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first">
                                                        <div className='mt-5 '>
                                                            <Row className='justify-content-center mb-5'>
                                                                <Col lg="4" sm="6" xs="6">
                                                                    <Card className="bg-light border-0 cardstyle" onClick={handleClick}>
                                                                        <Card.Img src={blog1} alt="Card image" className='cardimg' />
                                                                        <Card.ImgOverlay>
                                                                            <Card.Title className='titlestyle'>Benefits of Generic Medicines</Card.Title>

                                                                            <Card.Text className='bluestyle'>Generic Pharmacy Business</Card.Text>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="4" sm="6" xs="6">
                                                                    <Card className="bg-light border-0 cardstyle" onClick={gettingfamilierDir}>
                                                                        <Card.Img src={blog2} alt="Card image" className='cardimg' />
                                                                        <Card.ImgOverlay>
                                                                            <Card.Title className='titlestyle'>Getting familiar with Generic Medicine</Card.Title>

                                                                            <Card.Text className='bluestyle'>Generic Pharmacy Business</Card.Text>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="4" sm="6" xs="6">
                                                                    <Card className="bg-light border-0 cardstyle" onClick={boosterDir}>
                                                                        <Card.Img src={blog2} alt="Card image" className='cardimg' />
                                                                        <Card.ImgOverlay>
                                                                            <Card.Title className='titlestyle'>Booster Generic Awareness</Card.Title>

                                                                            <Card.Text className='bluestyle'>Generic Pharmacy Business</Card.Text>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                            {/* <Row className='justify-content-center mb-5'>
                                                                <Col lg="4" sm="6" xs="6">
                                                                    <Card className="bg-light border-0 cardstyle">
                                                                        <Card.Img src={blog1} alt="Card image" className='cardimg' />
                                                                        <Card.ImgOverlay>
                                                                            <Card.Title className='titlestyle'>Add Tomatoes to your Salad Diet</Card.Title>

                                                                            <Card.Text className='bluestyle'>Food</Card.Text>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="4" sm="6" xs="6">
                                                                    <Card className="bg-light border-0 cardstyle">
                                                                        <Card.Img src={blog2} alt="Card image" className='cardimg' />
                                                                        <Card.ImgOverlay>
                                                                            <Card.Title className='titlestyle'>10 Essential Health Tips for a Healthy Lifestyle</Card.Title>

                                                                            <Card.Text className='bluestyle'>Lifestyle</Card.Text>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </Col>
                                                                <Col lg="4" sm="6" xs="6">
                                                                    <Card className="bg-light border-0 cardstyle">
                                                                        <Card.Img src={blog2} alt="Card image" className='cardimg' />
                                                                        <Card.ImgOverlay>
                                                                            <Card.Title className='titlestyle'>Managing Diabetes: Empowering Strategies for a Healthier Life</Card.Title>

                                                                            <Card.Text className='bluestyle'>Personal Care</Card.Text>
                                                                        </Card.ImgOverlay>
                                                                    </Card>
                                                                </Col>
                                                            </Row> */}
                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second"></Tab.Pane>
                                                    <Tab.Pane eventKey="third"></Tab.Pane>
                                                    <Tab.Pane eventKey="fourth"></Tab.Pane>
                                                    <Tab.Pane eventKey="fifth"></Tab.Pane>
                                                    <Tab.Pane eventKey="sixth"></Tab.Pane>
                                                </Tab.Content>
                                            </Col>
                                        </Row>
                                    </Tab.Container>
                                </Col>
                            </Row>

                        </div>

                    </Container>
                </div >
            </Layout>
            {/* <div className='footer'>
                <Row className='justify-content-center'>
                    <Col lg="9">
                        <hr />
                        <Row>
                            <Col lg="3">
                                <img src={genivislogo} className='footerlogo mb-4' />
                                <p className='footertxt'>Genivis Remedies (I) Pvt. Ltd., Marvel Feugo,
                                    Office No. 6070, Opp. Seasons Mall,
                                    Magarpatta Road, Hadapsar, Pune, 411028.</p>
                                <Row className="mt-5">
                                    <Col lg="1">
                                        <img src={call} className="icon-style" />
                                    </Col>
                                    <Col lg="11">
                                        <p className="contact-style">+919145009191</p>
                                    </Col>
                                </Row>
                                <Row >
                                    <Col lg="1">
                                        <img src={gmail} className="icon-style" />
                                    </Col>
                                    <Col lg="11">
                                        <p className="contact-style">prm@genivis.in</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="1"></Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Company</h6>
                                    <li className='footertxt mb-2'>Home</li>
                                    <li className='footertxt mb-2'>About Us</li>
                                    <li className='footertxt mb-2'>Gallery</li>
                                    <li className='footertxt mb-2'>Careers</li>
                                    <li className='footertxt mb-2'>Contact Us</li>
                                    <li className='footertxt'>Blogs</li>
                                </ul>
                            </Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Our Services</h6>
                                    <li className='footertxt mb-2'>Our Products</li>
                                    <li className='footertxt mb-2'>Comapre Medicine</li>
                                    <li className='footertxt mb-2'>Locate Nearest Store</li>
                                    <li className='footertxt mb-2'>Know Generics</li>

                                </ul>
                            </Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Policies</h6>
                                    <li className='footertxt mb-2'>Privacy Policy</li>
                                    <li className='footertxt mb-2'>Terms & Conditions</li>
                                    <li className='footertxt mb-2'>Refund & Retun Policy</li>
                                </ul>
                            </Col>
                            <Col lg="2" className='justify-content-center'>
                                <ul style={{ listStyleType: "none" }}>
                                    <h6 className='footerhead'>Follow Us</h6>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faFacebook} className='me-2' />
                                        Facebook</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faInstagram} className='me-2' />
                                        Instagram</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faLinkedin} className='me-2' />
                                        Linkedin</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faYoutube} className='me-2' />
                                        Youtube</li>
                                    <li className='footertxt mb-2'>
                                        <FontAwesomeIcon icon={faSquareXTwitter} className='me-2' />
                                        X(Twitter)
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row className='text-center'>
                            <Col lg="12" className='justify-content-center'>
                                <div className='footerdiv text-center'>
                                    <Row className='justify-content-center '>
                                        <Col lg="4" className='text-end'>
                                            <p>Download the app by clicking the link:</p>
                                        </Col>
                                        <Col lg="4" className='text-start '>
                                            <img src={imageplay} className='footerimg me-3' />
                                            <img src={appstore} className='footerimg' />
                                        </Col>

                                    </Row>
                                </div>
                            </Col>
                            <Col lg="12" className='justify-content-center'>
                                <p className='mt-2'>©Genivis Remedies (I) Pvt. Ltd. All rights reserved.</p>
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div> */}
        </div>
    )
}
