import { useEffect, useRef, useState, useLayoutEffect } from 'react'
import {
    Nav,
    Button,
    Offcanvas, Navbar, NavDropdown, Form, Container,
    Row, Col, Carousel, Card,
    CardTitle
} from "react-bootstrap";
import CountUp from 'react-countup';
import Slider from "react-slick";
import topsticky from "../assets/images/topsticky.png";
import vector from "../assets/images/vector.png";
import businessman from "../assets/images/businessman.png";
import headerimg from "../assets/images/headerimg.png";
import milestone1st from "../assets/images/milestone1st.png";
import image8 from "../assets/images/image8.png";
import franchise2024 from "../assets/images/franchise2024.png";
import trophy from "../assets/images/trophy.png";
import milestones from "../assets/images/milestones.png";
import medicalshop from "../assets/images/medicalshop.png";
import gv from "../assets/images/gv.png";
import blog1 from "../assets/images/blog1.png";
import blog2 from "../assets/images/blog2.png";
import testimonials from "../assets/images/testimonials.png";
import skyogen from "../assets/images/skyogen.png";
import Frame1 from "../assets/images/Frame1.png";
import frame2 from "../assets/images/frame2.png";
import frame3 from "../assets/images/frame3.png";
import frame4 from "../assets/images/frame4.png";
import frame6 from "../assets/images/frame6.png";
import frame7 from "../assets/images/frame7.png";
import frame8 from "../assets/images/frame8.png";
import frame9 from "../assets/images/frame9.png";
import frame10 from "../assets/images/frame10.png";
import { useNavigate } from 'react-router-dom';
import gmpcertifiedblue from "../assets/images/gmpcertifiedblue.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faSquareXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import Layout from './Layout';
export default function Home() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/blogs');
    };
    const benifitBlog = () => {
        navigate('/blogdetails');
    };
    const gettingfamilierDir = () => {
        navigate('/gettingfamilier');
    };
    const handleDropdownItemClick = (type) => {
        navigate(`/becomebusinesspartner/${type}`);
    };
    // const [videoPlaying, setVideoPlaying] = useState(false); // State to track video playing
    // const [selectedIndex, setSelectedIndex] = useState(0); // State to track selected carousel item index

    // const handleVideoPlay = (index) => {
    //     setVideoPlaying(true);
    //     setSelectedIndex(index); // Update selected index when video starts playing
    // };

    // const handleVideoPause = () => {
    //     setVideoPlaying(false);
    // };

    // const handleSelect = (selectedIndex, e) => {
    //     if (videoPlaying) {
    //         e.preventDefault(); // Prevent carousel from changing slides if video is playing
    //     } else {
    //         setSelectedIndex(selectedIndex); // Update selected index if no video is playing
    //     }
    // };
    // const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
    const isSafari = /Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor);

    useEffect(() => {
        const handleScroll = () => {
            // const boxes = document.querySelectorAll('.box');
            const cardstyles = document.querySelectorAll('.cardstyle');
            // boxes.forEach(box => {
            //     const boxPosition = box.getBoundingClientRect().top;
            //     const screenPosition = window.innerHeight / 1.3; // Adjust as needed

            //     if (boxPosition < screenPosition) {
            //         box.classList.add('animate'); // Add your animation class here
            //     }
            // });
            cardstyles.forEach(cardstyle => {
                const cardstylePosition = cardstyle.getBoundingClientRect().top;
                const screenPosition = window.innerHeight / 1.3; // Adjust as needed

                if (cardstylePosition < screenPosition) {
                    cardstyle.classList.add('animate'); // Add your animation class here
                }
            });
            // const franchiseSection = document.querySelector('.franchisesection');
            // const partnerSection = document.querySelector('.partnersection');
            // const partnerimg = document.querySelector('.partnerimg');
            // const triggerPoint = window.innerHeight / 1.2; // Adjust as needed
            // if (franchiseSection.getBoundingClientRect().top < triggerPoint) {
            //     console.log('Franchise section in view');
            //     franchiseSection.classList.add('animate-left');
            //     partnerimg.classList.add('animate-left');
            // }
            // if (partnerSection.getBoundingClientRect().top < triggerPoint) {
            //     console.log('Partner section in view');
            //     partnerSection.classList.add('animate-right');
            //     partnerimg.classList.add('animate-right');
            // }

        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };


    }, []);


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for smooth scrolling
        });
    };

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div>
            <Layout>
                <div className={`${isSafari == true ? "safari-style" : "home-style"}`}>
                    <Container fluid>
                        {/* <Carousel data-bs-theme="dark" >
                            <Carousel.Item>
                                <div className='background1 safari'>
                                    <Row className='justify-content-center pt-5'>
                                        <Col lg="1" className='my-auto text-center'>
                                            <img
                                                className="logoimg"
                                                src={gmpcertifiedblue}
                                                alt="Second slide"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col lg="7" className='text-center'>
                                            <p className='headtext'>
                                                <span style={{ color: "#2b4bb6" }}>India's No.1</span>  Fastest Growing
                                                Generic Medicine Manufacturing
                                                And Distribution Company
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col lg="6" className='text-center'>
                                            <Row>
                                                <Col lg="4" md="4" sm="12" xs="12">
                                                    <div className='badgestyle'>
                                                        <span><b>75 Lakh+</b></span>Satisfied Customer
                                                    </div>
                                                </Col>
                                                <Col lg="4" md="4" sm="6" xs="6">
                                                    <div className='badgestylegreen'>
                                                        <span><b>4652+</b></span>Pharmacist
                                                    </div>
                                                </Col>

                                                <Col lg="4" md="4" sm="6" xs="6" className=''>
                                                    <div className='badgestyle'>
                                                        <span><b>2000+</b></span>Franchaises
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>

                                    </Row>
                                </div>

                                <Row className='justify-content-center'>
                                    <Col lg="6" className='justify-content-center'>
                                        {/* <img
                                            className="d-block w-100 headerimg"
                                            src={headerimg}
                                            alt="Second slide"
                                        />
                                        <iframe
                                            className="d-block w-100 headerimg"
                                            src="https://www.youtube.com/embed/aYr448jKfP8"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                        ></iframe>
                                    </Col>
                                </Row>
                            </Carousel.Item>
                            <Carousel.Item >
                                <div className='background'>
                                    <Row className='justify-content-center pt-5'>
                                        <Col lg="1" className='my-auto text-center'>
                                            <img
                                                className="logoimg"
                                                src={gmpcertifiedblue}
                                                alt="Second slide"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col lg="7" className='text-center'>
                                            <p className='headtext'>
                                                Join Low Investment, <br></br>
                                                High returns <span style={{ color: "#2b4bb6" }}>Generic Business</span>
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <Row className='justify-content-center'>
                                    <Col lg="6" className='justify-content-center'>
                                        <img
                                            className="d-block w-100 headerimg"
                                            src={headerimg}
                                            alt="Second slide"
                                        />
                                    </Col>
                                </Row>


                            </Carousel.Item>
                            <Carousel.Item >
                                <div className='background3'>
                                    <Row className='justify-content-center pt-5'>
                                        <Col lg="1" className='my-auto text-center'>
                                            <img
                                                className="logoimg"
                                                src={gmpcertifiedblue}
                                                alt="Second slide"
                                            />
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center'>
                                        <Col lg="7" className='text-center'>
                                            <p className='headtext'>
                                                Join us to <span style={{ color: "#2b4bb6" }}>secure your lifetime income</span>
                                                with the fastest-growing generic brand
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                                {/* <Row className='justify-content-center'>
                                    <Col lg="6" className='justify-content-center'>
                                        <img
                                            className="d-block w-100 headerimg"
                                            src={headerimg}
                                            alt="Second slide"
                                        />
                                    </Col>
                                </Row>


                            </Carousel.Item>
                        </Carousel> */}
                        <div className='dividersection'>
                        <div className='carousel-container'>
                            <Carousel data-bs-theme="dark">
                                <Carousel.Item>
                                    <div className='background1 safari'>
                                        <Row className='justify-content-center pt-5'>
                                            <Col lg="1" className='my-auto text-center'>
                                                <img
                                                    className="logoimg"
                                                    src={gmpcertifiedblue}
                                                    alt="First slide"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col lg="7" className='text-center'>
                                                <p className='headtext'>
                                                    <span style={{ color: "#2b4bb6" }}>India's No.1</span>  Fastest Growing
                                                    Generic Medicine Manufacturing
                                                    And Distribution Company
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col lg="6" className='text-center'>
                                                <Row>
                                                    <Col lg="4" md="4" sm="12" xs="12">
                                                        <div className='badgestyle'>
                                                            <span><b>75 Lakh+</b></span>Satisfied Customer
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="6">
                                                        <div className='badgestylegreen'>
                                                            <span><b>4652 </b></span>Pharmacist
                                                        </div>
                                                    </Col>
                                                    <Col lg="4" md="4" sm="6" xs="6">
                                                        <div className='badgestyle'>
                                                            <span><b>2000+</b></span>Franchaises
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='background'>
                                        <Row className='justify-content-center pt-5'>
                                            <Col lg="1" className='my-auto text-center'>
                                                <img
                                                    className="logoimg"
                                                    src={gmpcertifiedblue}
                                                    alt="Second slide"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col lg="7" className='text-center'>
                                                <p className='headtext'>
                                                    Join Low Investment, <br></br>
                                                    High returns <span style={{ color: "#2b4bb6" }}>Generic Business</span>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className='background3'>
                                        <Row className='justify-content-center pt-5'>
                                            <Col lg="1" className='my-auto text-center'>
                                                <img
                                                    className="logoimg"
                                                    src={gmpcertifiedblue}
                                                    alt="Third slide"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className='justify-content-center'>
                                            <Col lg="7" className='text-center'>
                                                <p className='headtext'>
                                                    Join us to <span style={{ color: "#2b4bb6" }}>secure your lifetime income </span>
                                                    with the fastest-growing generic brand
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Carousel.Item>

                            </Carousel>

                            </div>
                            {/* <div>
                            <Row className='justify-content-center'>
                                <Col lg="6" md="11" sm="11" xs="11">
                                    <div className="video-container">
                                        <iframe
                                            className="d-block w-100 headerimg"
                                            src="https://www.youtube.com/embed/aYr448jKfP8"
                                            title="YouTube video player"
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        ></iframe>
                                    </div>
                                </Col>
                                </Row>
                            </div> */}

                            <Row className='justify-content-center'>
                                <Col lg="6" md={11} sm={11} xs={11} className='bannerslik'>
                                    <Carousel data-bs-theme="dark" style={{ overflow: 'visible' }}
                                        interval={null}
                                    // activeIndex={videoPlaying ? null : 0} // Stop auto scrolling when video is playing
                                    // onSelect={(selectedIndex, e) => {
                                    //     if (videoPlaying) {
                                    //         e.preventDefault(); // Prevent carousel from changing slides if video is playing
                                    //     }
                                    // }}
                                    // activeIndex={selectedIndex}
                                    // onSelect={handleSelect}
                                    >
                                        <Carousel.Item>
                                            <div className="video-container">
                                                <iframe
                                                    className="d-block w-100 headerimg"
                                                    src="https://www.youtube.com/embed/tDgfOdIzQGY"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                // onLoad={() => handleVideoPlay(0)}
                                                // onPause={handleVideoPause}
                                                ></iframe>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="video-container">
                                                <iframe
                                                    className="d-block w-100 headerimg"
                                                    src="https://www.youtube.com/embed/aYr448jKfP8"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    // onLoad={() => handleVideoPlay(0)}
                                                    // onPause={handleVideoPause}
                                                ></iframe>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="video-container">
                                                <iframe
                                                    className="d-block w-100 headerimg"
                                                    src="https://www.youtube.com/embed/IlP68pbG94I"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    // onLoad={() => handleVideoPlay(1)}
                                                    // onPause={handleVideoPause}
                                                ></iframe>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="video-container">
                                                <iframe
                                                    className="d-block w-100 headerimg"
                                                    src="https://www.youtube.com/embed/TMNJc4SQTnQ"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    // onLoad={() => handleVideoPlay(2)}
                                                    // onPause={handleVideoPause}
                                                ></iframe>
                                            </div>

                                        </Carousel.Item>
                                        <Carousel.Item>
                                            <div className="video-container">
                                                <iframe
                                                    className="d-block w-100 headerimg"
                                                    src="https://www.youtube.com/embed/oUbNdnmIQKM"
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    // onLoad={() => handleVideoPlay(3)}
                                                    // onPause={handleVideoPause}
                                                ></iframe>
                                            </div>

                                        </Carousel.Item>
                                    </Carousel>

                                </Col>
                            </Row>



                            <div className='aboutus'>
                            <div className='bgcolor'>
                                <div className='text-end'>
                                    <img src={topsticky} className='topsticky' onClick={scrollToTop} />
                                </div>
                                <Row className="justify-content-center">
                                        <Col lg="9" className='aboutmg'>
                                        <Row className='mt-5'>
                                            <Col lg="6" md="6" sm="12" xs="12" className=''>
                                                <h1 className='text-white'>Who we are</h1>
                                                <p className='textstyle'>
                                                    Genivis Remedies (I) Pvt. Ltd. is a dynamic and innovative pharmaceutical company dedicated to enhancing healthcare through our extensive network of <span style={{ fontWeight: "500" }}>generic medicine franchise stores.  </span>

                                                </p>
                                                <p className='textstyle'>
                                                    We are on a mission to make high-quality, affordable <span style={{ fontWeight: "500" }}>generic medicines</span>  accessible to everyone and we are continuously expanding our business by appointing new franchises across the country.
                                                </p>
                                                <Button type="button" className='franchisebtn'
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        navigate("/aboutus");
                                                    }}
                                                >Know More</Button>
                                            </Col>
                                            <Col lg="6" md="6" sm="12" xs="12">
                                                <img
                                                    className="w-100 "
                                                    src={gv}
                                                    alt="Second slide"
                                                />
                                            </Col>
                                            </Row>
                                            <Row className='mt-5'>
                                                <Col lg="3" md="3" sm="6" xs="6">
                                                    <div className='box'>
                                                        <p className='boxtext'>Conceptualization Since</p>
                                                        <h1>
                                                            <CountUp end={2016} duration={5} separator='' enableScrollSpy />
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col lg="3" md="3" sm="6" xs="6">
                                                    <div className='box'>
                                                        <p className='boxtext'>Number Of Products</p>
                                                        <h1>
                                                            <CountUp end={5000} duration={5} suffix="+" separator='' enableScrollSpy />
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col lg="3" md="3" sm="6" xs="6">
                                                    <div className='box'>
                                                        <p className='boxtext'>Number Of Pharmacist</p>
                                                        <h1>
                                                            <CountUp end={4652} duration={5} separator='' enableScrollSpy />
                                                        </h1>
                                                    </div>
                                                </Col>
                                                <Col lg="3" md="3" sm="6" xs="6">
                                                    <div className='box'>
                                                        <p className='boxtext'>Number Of Franchises</p>
                                                        <h1>
                                                            <CountUp end={2000} duration={5} suffix="+" separator='' enableScrollSpy />
                                                        </h1>
                                                    </div>
                                                </Col>
                                            </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        </div>
                        <div className="ourmilestone">
                            <div className="slider-container">
                                <Row className="justify-content-center mb-5">
                                    <Col className="justify-content-center" lg="9">
                                        <p className='head'>
                                            Our Milestones
                                        </p>
                                        <Slider {...settings}>
                                            <div>
                                                <Card className='cardstyle'>
                                                    <Card.Body>
                                                        <img variant="top" src={milestone1st} className='carouselimg' />
                                                        <Card.Title className='ffamily'>@Genivis</Card.Title>
                                                        <div className='mb-2'>
                                                            <Row>
                                                                <Col lg="1" md="1" sm="1" xs="1" className='pe-0'>
                                                                    <img src={milestones} className='flag' />
                                                                </Col>
                                                                <Col lg="11" md="11" sm="11" xs="11" className='ps-0'>
                                                                    <div style={{ border: "2px solid #FF9133", width: "110%" }} className='mt-2'></div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Card.Title className='cardhead mb-2'>Opened 2000+ Franchises</Card.Title>
                                                        <Card.Text className='cardtext'>
                                                            Reducing every Indians citizen healthcare budget by providing quality generic medicines at attainable prices by expanding our presence in multiple states of India with more than 10,000 stores.
                                                        </Card.Text>
                                                        {/* <Card.Link href="#">Card Link</Card.Link>
                                                        <Card.Link href="#">Another Link</Card.Link> */}
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div>
                                                <Card className='cardstyle'>
                                                    <Card.Body>
                                                        <img variant="top" src={franchise2024} className='carouselimg' />
                                                        <Card.Title className='ffamily'>29th May 2024</Card.Title>
                                                        <div className='mb-2'>
                                                            <Row>
                                                                <Col lg="1" md="1" sm="1" xs="1" className='pe-0'>
                                                                    <img src={milestones} className='flag' />
                                                                </Col>
                                                                <Col lg="11" md="11" sm="11" xs="11" className='ps-0'>
                                                                    <div style={{ border: "2px solid #FF9133", width: "110%" }} className='mt-2'></div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Card.Title className='cardhead mb-2'>Most Promising Retailer</Card.Title>

                                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                        <Card.Text className='cardtext'>
                                                            Our company has been recognized by Silicon India magazine as a Most Promising Retailer Franchise Company for 2024 in pan India.
                                                        </Card.Text>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div>
                                                <Card className='cardstyle'>
                                                    <Card.Body>
                                                        <img variant="top" src={image8} className='carouselimg' />
                                                        <Card.Title className='ffamily'>29th May 2024</Card.Title>
                                                        <div className='mb-2'>
                                                            <Row>
                                                                <Col lg="1" md="1" sm="1" xs="1" className='pe-0'>
                                                                    <img src={milestones} className='flag' />
                                                                </Col>
                                                                <Col lg="11" md="11" sm="11" xs="11" className='ps-0'>
                                                                    <div style={{ border: "2px solid #FF9133", width: "110%" }} className='mt-2'></div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Card.Title className='cardhead mb-2'>Titled with India's No.1</Card.Title>

                                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                        <Card.Text className='cardtext'>
                                                            Titled with India's No.1 Generic Medicines Franchise Company for excellence in affordable healthcare by Zee24Tas, Zee Media and Zee News on 29th May 2024.
                                                        </Card.Text>

                                                    </Card.Body>
                                                </Card>
                                            </div>

                                            <div>
                                                <Card className='cardstyle'>
                                                    <Card.Body>
                                                        <img variant="top" src={trophy} className='carouselimg' />
                                                        <Card.Title className='ffamily'>11th Dec 2023</Card.Title>
                                                        <div className='mb-2'>
                                                            <Row>
                                                                <Col lg="1" md="1" sm="1" xs="1" className='pe-0'>
                                                                    <img src={milestones} className='flag' />
                                                                </Col>
                                                                <Col lg="11" md="11" sm="11" xs="11" className='ps-0'>
                                                                    <div style={{ border: "2px solid #FF9133", width: "110%" }} className='mt-2'></div>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                        <Card.Title className='cardhead'>Top 10 Best Leader</Card.Title>

                                                        {/* <Card.Subtitle className="mb-2 text-muted">Card Subtitle</Card.Subtitle> */}
                                                        <Card.Text className='cardtext'>
                                                            Awarded as Top 10 Best Leaders in Pharma & Life sciences 2023 by CEO insight Magazine on 11 December 2023.
                                                        </Card.Text>

                                                    </Card.Body>
                                                </Card>
                                            </div>
                                            <div>
                                                <Card className='cardstyle'>
                                                    <Card.Body>
                                                        <img variant="top" src={trophy} className='carouselimg' style={{ visibility: "hidden" }} />
                                                        <Card.Title className='ffamily text-center'>Go Forward So Far</Card.Title>

                                                    </Card.Body>
                                                </Card>
                                            </div>

                                        </Slider>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className='benifits'>
                            <Row className='justify-content-center'>
                                {/* <Col lg="6" md="6" sm="12" xs="12">
                                    <Row className='justify-content-center'>
                                        <Col lg="4">
                                            <div className='img1'>
                                                <img src={medicalshop} className='partnerimg' />\
                                            </div>
                                        </Col>
                                    </Row>
                                    <div className='franchisesection '>
                                        <Row className='justify-content-center'>
                                            <Col lg="6">
                                                <h1 className='text-white benifithead'>Apply For Franchise</h1>
                                                <p className='subhead'>Our Benifits:</p>
                                                <div className='d-flex'>
                                                    <img src={vector} className='tick me-2' />
                                                    <p className='liststyle'>1 to 5 lakhs profitability per month</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <img src={vector} className='tick me-2' />
                                                    <p className='liststyle'>100% Documentation & Licensing - As per FDA Guidelines</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <img src={vector} className='tick me-2' />
                                                    <p className='liststyle'>Join with more than 2000 stores across PAN INDIA</p>
                                                </div>
                                                <div className='justify-content-start'>
                                                    <Button className='benifitsbtn' type="button">Franchise Enquiry</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col> */}
                                <Col lg="9" md="11" sm="12" xs="12">
                                    <div className='partnersection'>
                                        <Row className='justify-content-center'>
                                            <Col lg="6" md="6" sm="12" xs="12">
                                                <h1 className='text-white benifithead'>Become a Business Partner</h1>
                                                <p className='subhead'>Our Benifits:</p>
                                                <div className='d-flex'>
                                                    <img src={vector} className='tick me-2' />
                                                    <p className='liststyle'>1 lakh to 7 lakhs per month</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <img src={vector} className='tick me-2' />
                                                    <p className='liststyle'>Domestic and international tours.</p>
                                                </div>
                                                <div className='d-flex'>
                                                    <img src={vector} className='tick me-2' />
                                                    <p className='liststyle'>Half yearly and annual contest.</p>
                                                </div>
                                                <div className='justify-content-start'>
                                                    <Button className='benifitsbtn mt-3' type="button"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                             handleDropdownItemClick('Franchise');
                                                        }}
                                                    >Apply for Partner</Button>
                                                </div>
                                            </Col>
                                            <Col lg="6" md="6" sm="12" xs="12" className='imgindent'>
                                                <div className='img2'>
                                                    <img src={businessman} className='partnerimg' />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='blogs mt-5 mb-5'>
                            <Row className="justify-content-center">
                                <Col lg="9" className='text-center'>
                                    <p className='head'>
                                        Blogs
                                    </p>
                                    <p className='textstyle'>Get to know more about the world of generic medicines and the industry that is growing everyday.</p>
                                    <Row className='justify-content-center'>

                                        <Col lg="5" sm="6" xs="6">
                                            {/* onClick={benifitBlog} */}
                                            <Card className="bg-light border-0 cardstyle" >
                                                <a href="https://blogs.genivis.in/?p=8"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                <Card.Img src={blog1} alt="Card image" className='cardimg' />
                                                <Card.ImgOverlay>
                                                    <Card.Title className='cardhead'>Benefits of Generic Medicines</Card.Title>

                                                    <Card.Text className='bluestyle'>Generic Pharmacy Business</Card.Text>
                                                </Card.ImgOverlay>
                                                </a>
                                            </Card>
                                        </Col>

                                        {/* onClick={gettingfamilierDir} */}
                                        <Col lg="5" sm="6" xs="6">
                                            <Card className="bg-light border-0 cardstyle" >
                                                <a href="https://blogs.genivis.in/?p=26"
                                                    target="_blank"
                                                    rel="noopener noreferrer">
                                                <Card.Img src={blog2} alt="Card image" className='cardimg' />
                                                <Card.ImgOverlay>
                                                    <Card.Title className='cardhead'>Getting familiar with Generic Medicine</Card.Title>

                                                    <Card.Text className='bluestyle'>Generic Pharmacy Business</Card.Text>
                                                </Card.ImgOverlay>
                                                </a>
                                            </Card>
                                        </Col>

                                    </Row>
                                    <Row className='justify-content-center mt-5'>
                                        <Col lg="2">
                                            <Button type="button" className='franchisebtn'
                                                href="https://blogs.genivis.in/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                // onClick={(e) => {
                                                //     e.preventDefault();
                                                //     navigate("/blogs");
                                                // }}
                                            >Browse All Blogs</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className='testimonials mt-5 mb-5'>
                            <Row className="justify-content-center">
                                <Col lg="9" className='text-center'>
                                    <p className='head'>
                                        Franchise Testimonials
                                    </p>
                                    {/* <p className='textstyle'>Get to know more about the world of generic medicines and the industry that is growing everyday</p> */}
                                    <Row className='justify-content-center'>
                                        <Col lg="4" sm="6" xs="6">
                                            <iframe
                                                className="d-block w-100 videostyle"
                                                src="https://www.youtube.com/embed/YcbhbZtnrZ0"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                            ></iframe>
                                        </Col>
                                        <Col lg="4" sm="6" xs="6">
                                            <iframe
                                                className="d-block w-100 videostyle"
                                                src="https://www.youtube.com/embed/me1O8jZlY6c"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                            ></iframe>
                                        </Col>
                                        <Col lg="4" sm="6" xs="6">
                                            <iframe
                                                className="d-block w-100 videostyle"
                                                src="https://www.youtube.com/embed/IQOiaPyUl8o"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                            ></iframe>
                                        </Col>
                                    </Row>
                                    <Row className='justify-content-center mt-4'>
                                        <Col lg="4" sm="6" xs="6">
                                            <iframe
                                                className="d-block w-100 videostyle"
                                                src="https://www.youtube.com/embed/KchaQDAI8tM"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                            ></iframe>
                                        </Col>
                                        <Col lg="4" sm="6" xs="6">
                                            <iframe
                                                className="d-block w-100 videostyle"
                                                src="https://www.youtube.com/embed/Y1eqCbyc6iA"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                            ></iframe>
                                        </Col>
                                        <Col lg="4" sm="6" xs="6">
                                            <iframe
                                                className="d-block w-100 videostyle"
                                                src="https://www.youtube.com/embed/6OzP04zI4bE"
                                                title="YouTube video player"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            // allowFullScreen
                                            ></iframe>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </div>
                        <div className="cliendspeak">
                            <Row className="justify-content-center">
                                <Col lg="9" className='text-center'>
                                    <Row className='justify-content-center mt-5'>
                                        <Col lg="6" className='text-center'>
                                            <p className='head'>
                                                Clients Speak
                                            </p>
                                            <p className='textstyle'>
                                                Our clients speak highly of our commitment to excellence and transformative impact on their businesses. See what they have to say about us!
                                            </p>
                                        </Col>
                                    </Row>
                                    <Carousel data-bs-theme="dark" className='mb-5'>
                                        <Carousel.Item>
                                            <Card className='cardstyle'>
                                                <Card.Body className='p-0'>
                                                    <Row>
                                                        <Col lg="2">
                                                            <img src={testimonials} className='testimonialimg d-none d-lg-block' />
                                                        </Col>
                                                        <Col lg="9" className='my-auto'>
                                                            <Row>
                                                                <Col lg="10" sm="8" xs="8">
                                                                    <Card.Title className='cardhead'>Kavita Gosavi</Card.Title>
                                                                </Col>
                                                                <Col lg="2" sm="4" xs="4" className='px-0'>
                                                                    <p className='justify-content-end badgesince'>Partner Since:2018</p>
                                                                </Col>
                                                            </Row>

                                                            <Card.Text className='cardtxt'>
                                                                Three years ago, I had the pleasure of collaborating with Genivis Remedies (I) Pvt. Ltd.
                                                                It is commendable how much effort they put into customer service, deliverables, and time management.
                                                                Their constant follow ups helped me increase my business growth to 90% in just a few months. Genivis Remedies (I) Pvt. Ltd.
                                                                is the best platform and concept to start a business and make a career. The wide variety of elements they offer comes with zero shortages,
                                                                and their strong customer service is an additional advantage.
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Carousel.Item>
                                        {/* <Carousel.Item>
                                            <Card className='cardstyle'>
                                                <Card.Body className='p-0'>
                                                    <Row>
                                                        <Col lg="2">
                                                            <img src={testimonials} className='testimonialimg d-none d-lg-block' />
                                                        </Col>
                                                        <Col lg="9" className='my-auto'>
                                                            <Row>
                                                                <Col lg="10" sm="8" xs="8">
                                                                    <Card.Title className='cardhead'>Madhuri Shedge</Card.Title>
                                                                </Col>
                                                                <Col lg="2" sm="4" xs="4" className='px-0'>
                                                                    <p className='justify-content-end badgesince'>Partner Since:2019</p>
                                                                </Col>
                                                            </Row>

                                                            <Card.Text className='cardtxt'>
                                                                My experience with Genivis has been extremely rewarding. A welcoming team with an easy onboarding and documentation process. They have effective operational management and marketing associates.
                                                                <br />
                                                                Their relationship maintenance with the franchise owner is absolutely professional. They regularly deliver feedback in order to achieve ambitious outcomes from us.
                                                            </Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Carousel.Item> */}
                                        <Carousel.Item>
                                            <Card className='cardstyle'>
                                                <Card.Body className='p-0'>
                                                    <Row>
                                                        <Col lg="2">
                                                            <img src={testimonials} className='testimonialimg d-none d-lg-block' />
                                                        </Col>
                                                        <Col lg="9" className='my-auto'>
                                                            <Row>
                                                                <Col lg="10" sm="8" xs="8">
                                                                    <Card.Title className='cardhead'>Ashutosh Kadam</Card.Title>
                                                                </Col>
                                                                <Col lg="2" sm="4" xs="4" className='px-0'>
                                                                    <p className='justify-content-end badgesince'>Partner Since:2017</p>
                                                                </Col>
                                                            </Row>

                                                            <Card.Text className='cardtxt'>
                                                                We had a chance to be part of their interesting and interactive team.
                                                                Where we noticed drastic changes in my business with their support.
                                                                'Engaging with Genivis has been one of the best decisions in my career.
                                                                Their years of experience has proven real growth from time to time. </Card.Text>
                                                        </Col>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Carousel.Item>
                                    </Carousel>
                                </Col>
                            </Row>
                        </div>
                        <div className='partner'>
                            <Row className='justify-content-center py-5'>
                                <Col lg="9">

                                    <Row className='justify-content-center'>
                                        <Col lg="6" md="6" sm="12" xs="12" style={{ borderRight: "1px solid #D5E2E7" }}>
                                            <p className='head'>
                                                Brands We Are Partner With
                                            </p>
                                            <Row className='justify-content-center'>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={skyogen} className='partnerimg' />
                                                </Col>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame4} className='partnerimg' />
                                                </Col>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame3} className='partnerimg' />
                                                </Col>

                                            </Row>
                                            <Row className='justify-content-center'>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame2} className='partnerimg' />
                                                </Col>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={Frame1} className='partnerimg' />
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="6" md="6" sm="12" xs="12">
                                            <p className='head'>
                                                Our Media Partner
                                            </p>
                                            <Row className='justify-content-center'>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame6} className='partnerimg' />
                                                </Col>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame7} className='partnerimg' />
                                                </Col>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame8} className='partnerimg' />
                                                </Col>

                                            </Row>
                                            <Row className='justify-content-center'>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame9} className='partnerimg' />
                                                </Col>
                                                <Col lg="4" sm="6" xs="6">
                                                    <img src={frame10} className='partnerimg' />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>

                                </Col>
                            </Row>
                        </div>

                        {/* <div className='search'>
                            <Row className='justify-content-center mt-5 mb-5'>
                                <Col lg="9" md="9" sm="12" xs="12">
                                    <p className='head'>
                                        Find Nearest Store
                                    </p>
                                    <p className='text-center searchfield'>India's Fastest Growing Generic Medical Network</p>
                                    <Row className='justify-content-center'>
                                        <Col lg="6" md="6" sm="12" xs="12">
                                            <Form className="d-flex">
                                                <Form.Control
                                                    type="search"
                                                    placeholder="Enter the Pincode"
                                                    className="me-2 searchfield"
                                                    aria-label="Search"
                                                />
                                                <Button className='franchisebtn'>Search</Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>  */}
                    </Container>
                </div>

            </Layout>

        </div >
    )
}
