import React from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import Slider from "react-slick";
import awareness1 from "../assets/images/awareness/awareness1.jpg";
import awareness2 from "../assets/images/awareness/awareness2.jpg";
import awareness3 from "../assets/images/awareness/awareness3.jpg";
import awareness4 from "../assets/images/awareness/awareness4.jpg";
import awareness5 from "../assets/images/awareness/awareness5.jpg";
import awareness6 from "../assets/images/awareness/awareness6.jpg";
import awareness7 from "../assets/images/awareness/awareness7.jpg";
export default function GenericMedicineAwareness() {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };
    return (
        <div>
            <p className='subhead text-start'>Generic Medicine Awareness</p>
            <Row className='justify-content-center sliderdivtraining mb-4'>
                <Col lg="9">
                    <p className='ffamily text-center pt-4'>Spotlight</p>
                    <Slider {...settings} className=''>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness1} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness2} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness3} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness4} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness5} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness6} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={awareness7} className='sliderimgmarketing' />
                            </Col>
                        </Row>

                    </Slider>
                </Col>
            </Row>
            <div className='mb-5'>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/U6VG3UvTSTQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/XQ9u6nBOPdw"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/l4MJ1C-fXAU"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/uWbmIIsS4bs"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                </Row>
                <Row className='mt-4 justify-content-center'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/r-wPYyXhyhE"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>

                </Row>
            </div>
        </div>
    )
}
