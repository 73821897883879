import React, { useState } from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import Slider from "react-slick";
import awardvideo from "../assets/images/awardvideo.mp4";
import bigfm from "../assets/images/bigfm.mp4";
import award1 from "../assets/images/awardsnew/award1.jpg";
import award2 from "../assets/images/awardsnew/award2.jpg";
import award3 from "../assets/images/awardsnew/award3.jpg";
import award4 from "../assets/images/awardsnew/award4.jpg";
export default function AwardsRecognition() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };
    return (
        <div>
            <p className='subhead text-start'>Award & Recognition's</p>
            <Row className='justify-content-center sliderdivtraining mb-4'>
                <Col lg="9">
                    <p className='ffamily text-center pt-4'>Spotlight</p>
                    <Slider {...settings} className=''>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={award1} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={award2} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={award3} className='sliderimgmarketing' />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                <img src={award4} className='sliderimgmarketing' />
                            </Col>
                        </Row>


                    </Slider>
                </Col>
            </Row>
            <div className='mb-4'>
                <Row className='justify-content-center mb-4'>
                    <Col lg="6" md="6" sm="6" xs="6">
                        <iframe
                            className="d-block w-100 viedostyle"
                            src="https://www.youtube.com/embed/TMNJc4SQTnQ"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe>
                    </Col>
                    <Col lg="6" md="6" sm="6" xs="6">
                        {/* <iframe
                            className="d-block w-100 viedostyle"
                            src={awardvideo}
                            type="video/mp4"
                            // title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe> */}
                        <video
                            className="d-block w-100 viedostyle"
                            src={awardvideo}
                            controls
                            autoPlay={false}  // Ensure autoplay is false
                        >
                        </video>
                    </Col>

                </Row>
                <Row>
                    <Col lg="6" md="6" sm="6" xs="6">
                        {/* <iframe
                            className="d-block w-100 viedostyle"
                            src={`${bigfm}?autoplay=0`}
                            type="video/mp4"
                            // title="YouTube video player"

                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        // allowFullScreen
                        ></iframe> */}
                        <video
                            className="d-block w-100 viedostyle"
                            src={bigfm}
                            controls
                            autoPlay={false}  // Ensure autoplay is false
                        >
                        </video>
                    </Col>
                </Row>
            </div>
        </div>
    )
}
