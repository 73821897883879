import React, { useState } from 'react'
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import Slider from "react-slick";
import training5 from "../assets/images/trainingnew/training5.jpg";
import training6 from "../assets/images/trainingnew/training6.jpg";
import training20 from "../assets/images/trainingnew/training20.jpg";
import training7 from "../assets/images/trainingnew/training7.jpg";
import training8 from "../assets/images/trainingnew/training8.jpg";
import training1 from "../assets/images/trainingnew/training1.jpg";
import training2 from "../assets/images/trainingnew/training2.jpg";
import training21 from "../assets/images/trainingnew/training21.jpg";
import training22 from "../assets/images/trainingnew/training22.jpg";
import train1 from "../assets/images/trainingnew/train1.jpeg";
import train2 from "../assets/images/trainingnew/train2.jpeg";
import training50 from "../assets/images/trainingnew/training50.jpg";

import training19 from "../assets/images/trainingnew/training19.jpg";
import training3 from "../assets/images/trainingnew/training3.jpg";
import training18 from "../assets/images/trainingnew/training18.jpg";
import training16 from "../assets/images/trainingnew/training16.jpg";
import training17 from "../assets/images/trainingnew/training17.jpg";
import training11 from "../assets/images/trainingnew/training11.jpg";
import training12 from "../assets/images/trainingnew/training12.jpg";
import training13 from "../assets/images/trainingnew/training13.jpg";
import training14 from "../assets/images/trainingnew/training14.jpg";
import training15 from "../assets/images/trainingnew/training15.jpg";
import training9 from "../assets/images/trainingnew/training9.jpg";
import training4 from "../assets/images/trainingnew/training4.jpg";
import training10 from "../assets/images/trainingnew/training10.jpg";
export default function TrainingProgram() {
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };
    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };
    return (
        <div>
            <>
                <p className='subhead text-start'>Training Program</p>
                <Row className='justify-content-center sliderdivtraining mb-4'>
                    <Col lg="9">
                        <p className='ffamily text-center pt-4'>Spotlight</p>
                        <Slider {...settings} className=''>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training19} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training3} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training18} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training16} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training17} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training11} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training12} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training13} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training14} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training15} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training10} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training9} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training4} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={train1} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={train2} className='sliderimgtraining' />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                    <img src={training50} className='sliderimgtraining' />
                                </Col>
                            </Row>
                        </Slider>
                    </Col>
                </Row>
                <div className='mb-4'>
                    <Row className='justify-content-center'>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training22} className='img-style' onClick={() => handleShow(training22)} />
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training21} className='img-style' onClick={() => handleShow(training21)} />
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training2} className='img-style' onClick={() => handleShow(training2)} />
                        </Col>
                    </Row>
                    <Row className='mt-4 justify-content-center'>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training1} className='img-style' onClick={() => handleShow(training1)} />
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training8} className='img-style' onClick={() => handleShow(training8)} />
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training7} className='img-style' onClick={() => handleShow(training7)} />
                        </Col>
                    </Row>
                    <Row className='mt-4 justify-content-center'>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training20} className='img-style' onClick={() => handleShow(training20)} />
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training6} className='img-style' onClick={() => handleShow(training6)} />
                        </Col>
                        <Col lg="4" md="4" sm="6" xs="6">
                            <img src={training5} className='img-style' onClick={() => handleShow(training5)} />
                        </Col>
                    </Row>
                    <Modal show={show} onHide={handleClose} centered className='modal-lg'>
                        <Modal.Body className='text-center'>
                            <img src={selectedImage} alt="Selected" className='img-fluid' />
                        </Modal.Body>
                    </Modal>
                </div >
            </>
        </div>
    )
}
