import React from 'react'
import {
    Nav,
    Button,
    Offcanvas, Navbar, NavDropdown, Form, Container,
    Row, Col, Carousel, Card,
    CardTitle, Tab, Breadcrumb
} from "react-bootstrap";
import blogd1 from "../assets/images/blogd1.png";
import blog3 from "../assets/images/blog3.png";
import genivislogo from "../assets/images/genivislogo.png";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import x from "../assets/images/x.png";
import insta from "../assets/images/insta.png";
import fb from "../assets/images/fb.png";
import youtube from "../assets/images/youtube.png";
import linkedin from "../assets/images/linkedin.png";
import appstore from "../assets/images/appstore.png";
import imageplay from "../assets/images/imageplay.png";
import recommended3 from "../assets/images/recommended3.png";
import recommended6 from "../assets/images/recommended6.png";
import recommended1 from "../assets/images/recommended1.png";
import recommended4 from "../assets/images/recommended4.png";
import recommended5 from "../assets/images/recommended5.png";
import tag from "../assets/images/tag.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faShare, faShareNodes, faTag } from '@fortawesome/free-solid-svg-icons';
import Layout from './Layout';
import { useNavigate } from 'react-router-dom';
export default function BoostergenericAwareness() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/blogdetails');
    };
    const gettingfamilierDir = () => {
        navigate('/gettingfamilier');
    };
    return (
        <div>
            <Layout>
                <Container fluid>
                    <div className='blogdetails mb-5 pt-4'>
                        <Row className='justify-content-center'>
                            <Col lg="9" sm="12" xs="12">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="blogs">Blogs</Breadcrumb.Item>
                                    <Breadcrumb.Item href="blogdetails" style={{ color: "#888" }}>
                                        Booster Generic Awareness
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <p className='head'>
                                    Booster Generic Awareness
                                </p>
                                <p className='bluestyle'>Generic Pharmacy Business</p>
                                <p className='subtext'>Published June 08, 2024 by the Genivis Team</p>
                                <Row>
                                    <Col lg="8" md="8" sm="12" xs="12">
                                        <img src={blog3} className='imgstyle' />
                                        <Row className='mt-3'>
                                            <Col lg="12" className=' d-flex my-auto'>
                                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                                </a>
                                                <div className='d-flex shareit'>
                                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                                </div>
                                            </Col>
                                        </Row>
                                        <p className='textstyle mt-3'>
                                            Affordable healthcare is a critical concern for many. Generic medicines offer a solution, providing the same
                                            therapeutic effects as brand-name drugs at a significantly lower cost. This article explores the importance
                                            of generic medicines, highlights the role of Genivis Remedies in promoting generic awareness and discusses
                                            the positive impact on public health.
                                        </p>
                                        <p className='headtitle'>Understanding Generic Medicines</p>
                                        <p className='textstyle mt-3'>Generic medicines are pharmaceutical products that are developed to be identical
                                            or bioequivalent to brand-name drugs. These medicines contain the same active ingredients, dosage form, strength,
                                            route of administration and intended use as their brand-name counterparts. However, they are usually sold at a
                                            lower price since their manufacturers do not have to invest in the research, development and marketing costs
                                            associated with creating a new drug. Generic medicines undergo rigorous testing by regulatory authorities to
                                            ensure their safety, quality and effectiveness.</p>
                                        <p className='textstyle mt-3'>It's important to note that while generic medicines have the same therapeutic effects
                                            as brand-name drugs, they may differ in appearance, packaging and inactive ingredients. These differences do not
                                            affect the medication's efficacy or safety. The World Health Organization (WHO) encourages the use of generic
                                            medicines as a cost-effective way to improve access to healthcare worldwide.
                                        </p>
                                        <p className='headtitle'>Benefits Of Generic Medicines</p>
                                        <p className='subhead'>Cost-Effectiveness :</p>
                                        <p className='textstyle mt-3'>Generics significantly reduce medication costs, making healthcare
                                            more accessible for everyone.</p>
                                        <p className='subhead'>Improved Healthcare Outcomes :</p>
                                        <p className='textstyle mt-3'>Affordability allows patients to adhere to treatment plans, leading to better health management.
                                        </p>
                                        <p className='subhead'>Increased Competition :</p>
                                        <p className='textstyle mt-3'>
                                            Generic availability fosters competition in the pharmaceutical industry, driving down prices for both generic and brand-name drugs.
                                        </p>
                                        <p className='subhead'>Resource Efficiency :</p>
                                        <p className='textstyle mt-3'>
                                            By choosing generics, healthcare systems can allocate resources more effectively, improving overall efficiency.
                                        </p>
                                        <p className='headtitle'>A Champion for Generics</p>
                                        <p className='textstyle mt-3'>Genivis Remedies is a pharmaceutical company dedicated to promoting generic awareness.
                                            Their initiatives include:
                                        </p>
                                        <p className='subhead'>Educational Campaigns :</p>
                                        <p className='textstyle mt-3'>
                                            Dispelling myths and misconceptions about generics through
                                            educational campaigns for healthcare professionals, pharmacists and patients.
                                        </p>
                                        <p className='subhead'>Collaboration with Healthcare Professionals :</p>
                                        <p className='textstyle mt-3'>
                                            Providing accurate information to healthcare
                                            providers to empower them to recommend generic alternatives.
                                        </p>
                                        <p className='subhead'>Patient Education :</p>
                                        <p className='textstyle mt-3'>
                                            Developing educational materials to increase patient understanding of
                                            generic medicines and their benefits.
                                        </p>
                                        <p className='subhead'>Quality Assurance :</p>
                                        <p className='textstyle mt-3'>
                                            Maintaining strict quality control measures to ensure the safety and
                                            efficacy of their generic medicines.
                                        </p>
                                        <p className='subhead'>Collaboration with Regulatory Authorities :</p>
                                        <p className='textstyle mt-3'>
                                            Working with regulatory bodies to ensure
                                            compliance with quality standards and promote generic use.
                                        </p>
                                        <p className='headtitle'>The Impact of Generic Awareness</p>
                                        <p className='textstyle mt-3'>Increased generic awareness benefits public health in several ways:</p>
                                        <p className='subhead'>Improved Access to Healthcare :</p>
                                        <p className='textstyle mt-3'>Affordable generics make healthcare more accessible to
                                            individuals of all socioeconomic backgrounds.</p>
                                        <p className='subhead'>Reduced Healthcare Costs :</p>
                                        <p className='textstyle mt-3'>Lower drug prices benefit patients, insurance providers, and
                                            governments, leading to more sustainable healthcare solutions.</p>
                                        <p className='subhead'>Enhanced Healthcare System Efficiency :</p>
                                        <p className='textstyle mt-3'>Choosing generics allows healthcare systems to
                                            allocate resources more effectively, improving service quality.</p>
                                        <p className='headtitle'>The Future of Generic Awareness</p>
                                        <p className='textstyle mt-3'>Genivis Remedies is committed to further enhancing generic awareness through initiatives like:</p>

                                        <p className='textstyle mt-3'>Reaching a wider audience with comprehensive information about generic medicines.</p>
                                        <p className='textstyle mt-3'>Empowering patients to make informed healthcare decisions through collaboration with patient
                                            advocacy groups.</p>
                                        <p className='textstyle mt-3'>Continued Research and Development. Expanding the portfolio of generic medicines to provide
                                            more affordable treatment options.</p>
                                        <p className='textstyle mt-3'>
                                            Engaging local communities and educating them about the benefits of generic medicines.
                                        </p>
                                        <p className='subhead'>
                                            By promoting generic awareness and advocating for their use, Genivis Remedies plays a
                                            vital role in improving public health and making healthcare more accessible for
                                            everyone.
                                        </p>
                                        <hr />
                                        <Row className='mt-3'>
                                            <Col lg="12" className=' d-flex my-auto'>
                                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                                </a>
                                                <div className='d-flex shareit'>
                                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" md="4" sm="12" xs="12">
                                        <p className='recommended'>
                                            Recommended Articles
                                        </p>
                                        <Row className='' onClick={handleClick}>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended6} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Benefits of Generic Medicines</p>
                                                <p className='bluestyle'>
                                                    Generic Pharmacy Business
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0' onClick={gettingfamilierDir}>
                                                <img src={recommended3} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Getting Familiar with Generic Medicine</p>
                                                <p className='bluestyle'>
                                                    Generic Pharmacy Business
                                                </p>
                                            </Col>
                                        </Row>
                                        {/* <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended4} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Add Tomatoes to your Salad Diet</p>
                                                <p className='bluestyle'>
                                                    Food
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended5} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>10 Essential Health Tips for a Healthy Lifestyle</p>
                                                <p className='bluestyle'>
                                                    Lifestyle
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3'>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended1} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Managing Diabetes: Empowering Strategies for a Healthier Life</p>
                                                <p className='bluestyle'>
                                                    Personal Care
                                                </p>
                                            </Col>
                                        </Row> */}
                                        <Row className='mt-5'>
                                            <Col lg="12">
                                                <p className='recommended'>
                                                    Explore More Tags
                                                </p>
                                            </Col>
                                            <Col>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    HealthCare
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Tips
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Personal Care
                                                </Button>

                                            </Col>

                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Generic Pharmacy Medicine
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Food
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>

                </Container>
            </Layout>
        </div>
    )
}
