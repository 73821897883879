import React from 'react'
import {
    Nav,
    Button,
    Offcanvas, Navbar, NavDropdown, Form, Container,
    Row, Col, Carousel, Card,
    CardTitle, Tab, Breadcrumb
} from "react-bootstrap";
import blogd1 from "../assets/images/blogd1.png";
import blog2 from "../assets/images/blog2.png";
import genivislogo from "../assets/images/genivislogo.png";
import call from "../assets/images/call.png";
import gmail from "../assets/images/gmail.png";
import x from "../assets/images/x.png";
import insta from "../assets/images/insta.png";
import fb from "../assets/images/fb.png";
import youtube from "../assets/images/youtube.png";
import linkedin from "../assets/images/linkedin.png";
import appstore from "../assets/images/appstore.png";
import imageplay from "../assets/images/imageplay.png";
import recommended3 from "../assets/images/recommended3.png";
import recommended6 from "../assets/images/recommended6.png";
import recommended1 from "../assets/images/recommended1.png";
import recommended4 from "../assets/images/recommended4.png";
import recommended5 from "../assets/images/recommended5.png";
import { useNavigate } from 'react-router-dom';
import tag from "../assets/images/tag.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin, faXTwitter, faYoutube, faSquareXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faShare, faShareNodes, faTag } from '@fortawesome/free-solid-svg-icons';
import Layout from './Layout';
export default function Gettingfamilierwithgm() {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/blogdetails');
    };
    const gettingfamilierDir = () => {
        navigate('/gettingfamilier');
    };
    const boosterDir = () => {
        navigate('/booster');
    };
    return (
        <div>
            <Layout>
                <Container fluid>
                    <div className='blogdetails mb-5 pt-4'>
                        <Row className='justify-content-center'>
                            <Col lg="9" sm="12" xs="12">
                                <Breadcrumb>
                                    <Breadcrumb.Item href="blogs">Blogs</Breadcrumb.Item>
                                    <Breadcrumb.Item href="blogdetails" style={{ color: "#888" }}>
                                        Getting Familiar with Generic Medicine
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <p className='head'>
                                    Getting Familiar with Generic Medicine
                                </p>
                                <p className='bluestyle'>Generic Pharmacy Business</p>
                                <p className='subtext'>Published October 12, 2021 by the Genivis Team</p>
                                <Row>
                                    <Col lg="8" md="8" sm="12" xs="12">
                                        <img src={blogd1} className='imgstyle' />
                                        <Row className='mt-3'>
                                            <Col lg="12" className=' d-flex my-auto'>
                                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                                </a>
                                                {/* <FontAwesomeIcon icon={faXTwitter} className='socialico me-3' /> */}
                                                <div className='d-flex shareit'>
                                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                                </div>
                                            </Col>
                                        </Row>

                                        <p className='textstyle mt-3'>In the simplest terms generic medicines are medications are manufactured to replicate an already
                                            marketed brand name medication with similar dosage form , safety , strength , route of administration , quality and intended
                                            use. In India, the escalating cost of healthcare is what led the people to opt for generic medicines. However there are
                                            still questions raised about the use and benefits of generic medicines. Therefore we are here to answer those questions.
                                        </p>
                                        <p className='headtitle'>FAQs About Generic Medications</p>
                                        <p className='subhead'>
                                            Q. Are generic medicines as effective as their counterpart branded medicines?</p>
                                        <p className='textstyle mt-3'>A. Yes , Generic medicines work the same as brand-name medicines. Hence it could be said that
                                            generic medicines are the perfect substitute to the brand name counter-parts. This is possible as it is necessary that
                                            the generic pharmacies go through a series of rigorous tests of pre-approval by the FDA. The FDA also monitors that the
                                            active pharmaceutical ingredients and that they provide the same effective similar to their brand-name counterpart while
                                            ensuring that it is safe. The FDA also ensures that the manufacturing plants and suppliers are following the regulations
                                            on good manufacturing practices by conducting routine inspections.
                                        </p>
                                        <p className='subhead'>Q. What is the reason that generic medications and branded medications appear different than each other?</p>
                                        <p className='textstyle mt-3'>A. The simple answer is that it is due to the trade laws and branding purposes are the reasons that the
                                            generic medicine pharmacies manufacture generic drugs that are different in color, taste and shape. However it does not change the
                                            use and benefits of the generic drugs as the active ingredients remain constant it is just the inactive ingredients that differ.
                                        </p>
                                        <p className='subhead'>Q. Is it always safe to substitute brand name medicines for generic medicines ?</p>
                                        <p className='textstyle mt-3'>
                                            A. Yes, generic medicines are always safe to use as a substitute for brand named ones as it is a fact that the active ingredients
                                            in a brand name drug when compared to a generic drug are identical. Therefore ensuring that the generic medicine suppliers provides
                                            similar use and benefits of the branded one.
                                        </p>

                                        <p className='subhead'> Q. How to start a generic medicine store ? More specifically in the region of Maharashtra?</p>
                                        <p className='textstyle mt-3'>
                                            A. There are two ways that a person could start a generic store, either by opening a medical store or by acquiring a generic medicine
                                            franchise. To open a generic medicine store in Maharashtra it is necessary that the applicant receives a government approved Drug license.
                                            Further providing blueprints of the location of the store to paying the Challan of the fee Necessary.
                                        </p>

                                        <p className='subhead'>Q. Why is the cost of generic medicines less than that of the branded ones ?</p>
                                        <p className='textstyle mt-3'>
                                            A. The cost of a Generic drug is 20 to 80 % lesser than that of its branded counter- part. The main reason behind this is that generic
                                            drug manufacturers do not invest as much as their counter-part brand name one. Generic medicines pharmacies do not need to invest in a
                                            separate laboratories as well as investment in the research and development department is inexpensive.
                                        </p>
                                        <p className='subhead'>To acquire a generic medicine franchise :</p>
                                        <p className='textstyle mt-3'>
                                            It is crucial to know that a wholesale FDA drug license is present as well as a Pharmacist holding a degree of either B.Pharm. / D.Pharm must
                                            be employed in the store or the franchise. To acquire the franchise it is also necessary that a rented/owned warehouse of around 400 sq. ft in
                                            addition to an invested of Rs 12,00,000 is necessary.
                                        </p>
                                        <hr />
                                        <Row className='mt-3'>
                                            <Col lg="12" className=' d-flex my-auto'>
                                                <p className='my-auto me-3 socialtxt'>Share On:</p>
                                                <a href="https://www.facebook.com/GenivisRemedies" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faFacebook} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.instagram.com/genivisremedies?igsh=a2R6MnAwNmV0aGht" target="_blank" rel="noopener noreferrer" >
                                                <FontAwesomeIcon icon={faInstagram} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.linkedin.com/company/100572660/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faLinkedin} className='socialico me-3' />
                                                </a>
                                                <a href="https://www.youtube.com/@genivisremedies/" target="_blank" rel="noopener noreferrer">
                                                <FontAwesomeIcon icon={faYoutube} className='socialico me-3' />
                                                </a>
                                                {/* <FontAwesomeIcon icon={faXTwitter} className='socialico me-3' /> */}
                                                <div className='d-flex shareit'>
                                                    <FontAwesomeIcon icon={faShareNodes} className='socialico border-0' /><p className='my-auto socialtxt'>Share</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col lg="4" md="4" sm="12" xs="12">
                                        <p className='recommended'>
                                            Recommended Articles
                                        </p>
                                        <Row onClick={handleClick}>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended6} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Benefits Of Generic Medicines</p>
                                                <p className='bluestyle'>
                                                    Generic Pharmacy Business
                                                </p>
                                            </Col>
                                        </Row>
                                        <Row className='mt-3' onClick={boosterDir}>
                                            <Col lg="3" sm="3" xs="3" className='pe-0'>
                                                <img src={recommended3} className='recommendedimg' />
                                            </Col>
                                            <Col lg="9" sm="9" xs="9">
                                                <p className='subhead'>Booster Generic Awareness</p>
                                                <p className='bluestyle'>
                                                    Generic Pharmacy Business
                                                </p>
                                            </Col>
                                        </Row>
                                        {/* <Row className='mt-3'>
                                        <Col lg="3" sm="3" xs="3" className='pe-0'>
                                            <img src={recommended4} className='recommendedimg' />
                                        </Col>
                                        <Col lg="9" sm="9" xs="9">
                                            <p className='subhead'>Add Tomatoes to your Salad Diet</p>
                                            <p className='bluestyle'>
                                                Food
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col lg="3" sm="3" xs="3" className='pe-0'>
                                            <img src={recommended5} className='recommendedimg' />
                                        </Col>
                                        <Col lg="9" sm="9" xs="9">
                                            <p className='subhead'>10 Essential Health Tips for a Healthy Lifestyle</p>
                                            <p className='bluestyle'>
                                                Lifestyle
                                            </p>
                                        </Col>
                                    </Row>
                                    <Row className='mt-3'>
                                        <Col lg="3" sm="3" xs="3" className='pe-0'>
                                            <img src={recommended1} className='recommendedimg' />
                                        </Col>
                                        <Col lg="9" sm="9" xs="9">
                                            <p className='subhead'>Managing Diabetes: Empowering Strategies for a Healthier Life</p>
                                            <p className='bluestyle'>
                                                Personal Care
                                            </p>
                                        </Col>
                                    </Row> */}
                                        <Row className='mt-5'>
                                            <Col lg="12">
                                                <p className='recommended'>
                                                    Explore More Tags
                                                </p>
                                            </Col>
                                            <Col>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    HealthCare
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Tips
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Personal Care
                                                </Button>

                                            </Col>

                                        </Row>
                                        <Row className='mt-2'>
                                            <Col>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Generic Pharmacy Medicine
                                                </Button>
                                                <Button className='tagstyle mt-2'>
                                                    <img src={tag} className='tagico' />
                                                    Food
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </div>

                </Container>
            </Layout>

        </div>
    )
}
