import React, { useState } from 'react';
import {
    Button, Row, Col, Container, Modal
} from "react-bootstrap";
import Layout from './Layout';
import gallery1 from "../assets/images/franchise/gallery1.png";
import gallery2 from "../assets/images/franchise/gallery2.png";
import gallery3 from "../assets/images/franchise/gallery3.png";
import gallery4 from "../assets/images/franchise/gallery4.png";
import gallery5 from "../assets/images/franchise/gallery5.png";
import gallery6 from "../assets/images/franchise/gallery6.png";
import gallery7 from "../assets/images/franchise/gallery7.png";
import gallery8 from "../assets/images/franchise/gallery8.png";
import gallery9 from "../assets/images/franchise/gallery9.png";
import gallery10 from "../assets/images/franchise/gallery10.png";
import gallery11 from "../assets/images/franchise/gallery11.png";
import gallery12 from "../assets/images/franchise/gallery12.png";
import gallery13 from "../assets/images/franchise/gallery13.png";
import gallery14 from "../assets/images/franchise/gallery14.png";
import gallery15 from "../assets/images/franchise/gallery15.png";
import gallery16 from "../assets/images/franchise/gallery16.png";
import gallery17 from "../assets/images/franchise/gallery17.png";
import gallery18 from "../assets/images/franchise/gallery18.png";
import gallery19 from "../assets/images/franchise/gallery19.png";
import gallery20 from "../assets/images/franchise/gallery20.png";
import sliderimg1 from "../assets/images/franchise/sliderimg1.png";
import sliderimg2 from "../assets/images/franchise/sliderimg2.png";
import img1 from "../assets/images/franchise/img1.png";
import img2 from "../assets/images/franchise/img2.png";
import img3 from "../assets/images/franchise/img3.png";
import sliderimg4 from "../assets/images/franchise/sliderimg4.png";
import Slider from "react-slick";

import TrainingProgram from './TrainingProgram';
import PressRelease from './PressRelease';
import AwardsRecognition from './AwardsRecognition';
import MarketingActivity from './MarketingActivity';
import OurWarehouse from './OurWarehouse';
import GenericMedicineAwareness from './GenericMedicineAwareness';
import Advertisement from './Advertisement';
import FranchiseModelDemo from './FranchiseModelDemo';
export default function FranchiseInauguration() {
    const [activeButton, setActiveButton] = useState("franchiseInauguration");
    const [show, setShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');

    const handleShow = (image) => {
        setSelectedImage(image);
        setShow(true);
    };

    const handleClose = () => {
        setShow(false);
        setSelectedImage('');
    };
    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId);
    };

    const renderContent = () => {
        switch (activeButton) {
            //Franchise Inauguration
            case "franchiseInauguration":
                return (
                    <>
                        <p className='subhead text-start'>Franchise Inaugurations</p>
                        <Row className='justify-content-center sliderdiv mb-4'>
                            <Col lg="9">
                                <p className='ffamily text-center pt-4'>Spotlight</p>
                                <Slider {...settings} className=''>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={sliderimg1} className='sliderimg' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={sliderimg2} className='sliderimg' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={gallery4} className='sliderimg' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={sliderimg4} className='sliderimg' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={img1} className='sliderimg' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={img2} className='sliderimg' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={img3} className='sliderimg' />
                                        </Col>
                                    </Row>
                                </Slider>
                            </Col>
                        </Row>
                        <div className='mb-4'>
                            <Row className='justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery2} className='img-style' onClick={() => handleShow(gallery2)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery3} className='img-style' onClick={() => handleShow(gallery3)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery6} className='img-style' onClick={() => handleShow(gallery6)} />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>

                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery4} className='img-style' onClick={() => handleShow(gallery4)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery5} className='img-style' onClick={() => handleShow(gallery5)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery7} className='img-style' onClick={() => handleShow(gallery7)} />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery8} className='img-style' onClick={() => handleShow(gallery8)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery10} className='img-style' onClick={() => handleShow(gallery10)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery12} className='img-style' onClick={() => handleShow(gallery12)} />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>

                                {/* <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery13} className='img-style' onClick={() => handleShow(gallery13)} />
                                </Col> */}
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery14} className='img-style' onClick={() => handleShow(gallery14)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery15} className='img-style' onClick={() => handleShow(gallery15)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery16} className='img-style' onClick={() => handleShow(gallery16)} />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery17} className='img-style' onClick={() => handleShow(gallery17)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery18} className='img-style' onClick={() => handleShow(gallery18)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery19} className='img-style' onClick={() => handleShow(gallery19)} />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>

                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={gallery20} className='img-style' onClick={() => handleShow(gallery20)} />
                                </Col>
                            </Row>
                            <Modal show={show} onHide={handleClose} centered className='modal-lg'>
                                <Modal.Body className='text-center'>
                                    <img src={selectedImage} alt="Selected" className='img-fluid' />
                                </Modal.Body>
                            </Modal>
                        </div >
                    </>
                );
            //training
            case "trainingProgram":
                return (
                    <>
                        <TrainingProgram />
                        {/* <p className='subhead text-start'>Training Program</p>
                        <Row className='justify-content-center sliderdivtraining mb-4'>
                            <Col lg="9">
                                <p className='ffamily text-center pt-4'>Spotlight</p>
                                <Slider {...settings} className=''>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training19} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training3} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training18} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training16} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training17} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training11} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training12} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training13} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training14} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training15} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training10} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training9} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training4} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={train1} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={train2} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={training50} className='sliderimgtraining' />
                                        </Col>
                                    </Row>
                                </Slider>
                            </Col>
                        </Row>
                        <div className='mb-4'>
                            <Row className='justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training22} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training21} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training2} className='img-style' />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training1} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training8} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training7} className='img-style' />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training20} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training6} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={training5} className='img-style' />
                                </Col>
                            </Row>

                        </div > */}
                    </>

                );
            case "awardRecognition":
                return (
                    <>
                        <AwardsRecognition />
                        {/* <p className='subhead text-start'>Award & Recognition's</p>
                        <div className='mb-4'>
                            <Row className='justify-content-center'>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <img src={award1} className='awardimg' />
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <img src={award2} className='awardimg' />
                                </Col>

                            </Row>

                            <Row className='justify-content-center mt-4'>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <img src={award3} className='awardimg' />
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <img src={award4} className='awardimg' />
                                </Col>

                            </Row>


                        </div> */}
                    </>
                );
            case "advertisementVideos":
                return (
                    <>
                        <Advertisement />
                    </>
                );
            case "genericMedicineAwareness":
                return (
                    <>
                        <GenericMedicineAwareness />
                        {/* <p className='subhead text-start'>Generic Medicine Awareness</p>
                        <div className='mb-5'>
                            <Row>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <iframe
                                        className="d-block w-100 viedostyle"
                                        src="https://www.youtube.com/embed/U6VG3UvTSTQ"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    // allowFullScreen
                                    ></iframe>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <iframe
                                        className="d-block w-100 viedostyle"
                                        src="https://www.youtube.com/embed/XQ9u6nBOPdw"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    // allowFullScreen
                                    ></iframe>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <iframe
                                        className="d-block w-100 viedostyle"
                                        src="https://www.youtube.com/embed/l4MJ1C-fXAU"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    // allowFullScreen
                                    ></iframe>
                                </Col>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <iframe
                                        className="d-block w-100 viedostyle"
                                        src="https://www.youtube.com/embed/uWbmIIsS4bs"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    // allowFullScreen
                                    ></iframe>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col lg="6" md="6" sm="6" xs="6">
                                    <iframe
                                        className="d-block w-100 viedostyle"
                                        src="https://www.youtube.com/embed/r-wPYyXhyhE"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    // allowFullScreen
                                    ></iframe>
                                </Col>

                            </Row>
                        </div> */}
                    </>

                );
            case "franchiseModelDemo":
                return (
                    <FranchiseModelDemo />
                );
            case "ourWarehouse":
                return (
                    <>
                        <OurWarehouse />
                        {/* <p className='subhead text-start'>Our Warehouse</p>
                        <Row>
                            <Col lg="6" md="6" sm="6" xs="6">
                                <iframe
                                    className="d-block w-100 viedostyle"
                                    src={warhosevd}
                                    type="video/mp4"
                                    // title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                // allowFullScreen
                                ></iframe>
                            </Col>
                        </Row> */}
                    </>
                );
            //Press Realese
            case "pressRelease":
                return (
                    <>
                        <PressRelease />
                        {/* <p className='subhead text-start'>Press Release</p>
                        <div className='mb-4'>
                            <Row className='justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p4} className='img-style' onClick={() => handleShow(p4)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={press1} className='img-style' onClick={() => handleShow(press1)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p1} className='img-style' onClick={() => handleShow(p1)} />
                                </Col>
                            </Row>
                            <Row className='justify-content-center mt-4'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p2} className='img-style' onClick={() => handleShow(p2)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p3} className='img-style' onClick={() => handleShow(p3)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p5} className='img-style' onClick={() => handleShow(p5)} />
                                </Col>
                            </Row>
                            <Row className='justify-content-center mt-4'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p6} className='img-style' onClick={() => handleShow(p6)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={p7} className='img-style' onClick={() => handleShow(p7)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={press22} className='img-style' onClick={() => handleShow(press22)} />
                                </Col>
                            </Row>
                            <Row className='justify-content-center mt-4'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={press33} className='img-style' onClick={() => handleShow(press33)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={press44} className='img-style' onClick={() => handleShow(press44)} />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={press66} className='img-style' onClick={() => handleShow(press66)} />
                                </Col>
                            </Row>
                            <Modal show={show} onHide={handleClose} centered className='modal-lg'>
                                <Modal.Body className='text-center'>
                                    <img src={selectedImage} alt="Selected" className='img-fluid' />
                                </Modal.Body>
                            </Modal>
                        </div> */}
                    </>
                );
            //Marketing
            case "marketingActivity":
                return (
                    <>
                        <MarketingActivity />
                        {/* <p className='subhead text-start'>Marketing Activity</p>
                        <Row className='justify-content-center sliderdivtraining mb-4'>
                            <Col lg="9">
                                <p className='ffamily text-center pt-4'>Spotlight</p>
                                <Slider {...settings} className=''>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact7} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact8} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact9} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact10} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact11} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact12} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12" style={{ textAlign: "-webkit-center" }}>
                                            <img src={mact13} className='sliderimgmarketing' />
                                        </Col>
                                    </Row>

                                </Slider>
                            </Col>
                        </Row>
                        <div className='mb-4'>
                            <Row className='justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={mact1} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={mact2} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={mact3} className='img-style' />
                                </Col>
                            </Row>
                            <Row className='mt-4 justify-content-center'>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={mact4} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={mact5} className='img-style' />
                                </Col>
                                <Col lg="4" md="4" sm="6" xs="6">
                                    <img src={mact6} className='img-style' />
                                </Col>
                            </Row>


                        </div > */}
                    </>
                );
            default:
                return null;
        }
    };

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    autoplay: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    autoplay: true,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                }
            }
        ]
    };

    return (
        <Layout>
            <Container fluid>
            <div className='franchiseinauguration pt-4'>
                <Row className="justify-content-center">
                    <Col lg="9">
                            <Row>
                                <Col lg="3" md="3" sm="12" xs="12" className=''>
                                    <p className='head text-start'>Gallery</p>
                                    <div className='wrap d-lg-block d-md-flex d-sm-flex d-xs-flex'>
                                <div>
                                            <Button
                                                id="franchiseInauguration"
                                                className={`btnstyle me-2 ${activeButton === "franchiseInauguration" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("franchiseInauguration")}
                                            >
                                                Franchise Inauguration
                                            </Button>
                                </div>
                                <div>
                                            <Button
                                                id="trainingProgram"
                                                className={`btnstyle me-2 ${activeButton === "trainingProgram" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("trainingProgram")}
                                            >
                                                Training Program
                                            </Button>
                                </div>
                                <div>
                                            <Button
                                                id="awardRecognition"
                                                className={`btnstyle me-2 ${activeButton === "awardRecognition" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("awardRecognition")}
                                            >
                                                Award & Recognition's
                                            </Button>
                                </div>
                                <div>
                                            <Button
                                                id="advertisementVideos"
                                                className={`btnstyle me-2 ${activeButton === "advertisementVideos" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("advertisementVideos")}
                                            >
                                                Advertisement Videos
                                            </Button>
                                </div>
                                <div>
                                            <Button
                                                id="genericMedicineAwareness"
                                                className={`btnstyle me-2 ${activeButton === "genericMedicineAwareness" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("genericMedicineAwareness")}
                                            >
                                                Generic Medicine Awareness
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="franchiseModelDemo"
                                                className={`btnstyle me-2 ${activeButton === "franchiseModelDemo" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("franchiseModelDemo")}
                                            >
                                                Franchise Model Demo
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="ourWarehouse"
                                                className={`btnstyle me-2 ${activeButton === "ourWarehouse" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("ourWarehouse")}
                                            >
                                                Our Warehouse
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="pressRelease"
                                                className={`btnstyle me-2 ${activeButton === "pressRelease" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("pressRelease")}
                                            >
                                                Press Release
                                            </Button>
                                        </div>
                                        <div>
                                            <Button
                                                id="marketingActivity"
                                                className={`btnstyle me-2 ${activeButton === "marketingActivity" ? "active" : ""}`}
                                                onClick={() => handleButtonClick("marketingActivity")}
                                            >
                                                Marketing Activity
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="9" md="9" sm="12" xs="12">
                                    {renderContent()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container>
        </Layout>
    );
}
