import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
// import "./App.css";
import Home from "@/Pages/Home";
import Blogs from "@/Pages/Blogs";
import BlogDetails from "@/Pages/BlogDetails";
import Layout from "@/Pages/Layout";
import Shop from "./Pages/Shop";
import BoostergenericAwareness from "./Pages/BoostergenericAwareness";
import Gettingfamilierwithgm from "./Pages/Gettingfamilierwithgm";
import FranchiseInauguration from "./Pages/FranchiseInauguration";
import AboutUs from "./Pages/AboutUs";
import BiggestMilestone from "./Pages/BiggestMilestone";
import AdministrativeTeam from "./Pages/AdministrativeTeam";
import BusinessPartner from "./Pages/BusinessPartner";
import WhyUs from "./Pages/WhyUs";
import Contact from "./Pages/Contact";
import MyMapComponent from "./Pages/MyMapComponent";
import BecomeBusinessPartner from "./Pages/BecomeBusinessPartner";
import Jobs from "./Pages/Jobs";
import Job1 from "./Pages/Job1";
import Job2 from "./Pages/Job2";
import Franchise from "./Pages/Franchise";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import LandingBecomeBusinessPartner from "./Pages/LandingBecomeBusinessPartner";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route element={<Layout />}> </Route> */}
          <Route path="/" element={<Home />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogdetails" element={<BlogDetails />} />
          <Route path="/booster" element={<BoostergenericAwareness />} />
          <Route path="/gettingfamilier" element={<Gettingfamilierwithgm />} />
          <Route path="/layout" element={<Layout />} />
          <Route path="/gallery" element={<FranchiseInauguration />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/biggestmilestone" element={<BiggestMilestone />} />
          <Route path="/administrativeteam" element={<AdministrativeTeam />} />
          <Route path="/businesspartner" element={<BusinessPartner />} />
          <Route path="/whyus" element={<WhyUs />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/job1" element={<Job1 />} />
          <Route path="/job2" element={<Job2 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/map" element={<MyMapComponent />} />
          <Route path="/franchise" element={<Franchise />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route
            path="/becomebusinesspartner/:type"
            element={<BecomeBusinessPartner />}
          />
          <Route
            path="/becomebusinessPartner"
            element={<LandingBecomeBusinessPartner />}
          />
          <Route path="*" element={<Navigate to="/" />} />
          {/* <Route
            path="/becomebusinesspartner"
            // key={"/becomebusinesspartner"}
            element={<BecomeBusinessPartner />} */}
          {/* /> */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
